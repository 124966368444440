<template>
  <footer id="main_footer">
    <div class="container">
      <ul class="footer_menu">
        <li>
          <a href="https://casademont.online/" target="_blank">› CASADEMONT</a>
        </li>
        <li>
          <a href="https://vinoterra.ru/" target="_blank">› VINOTERRA</a>
        </li>
      </ul>
      <div class="footer_bottom">
        <div class="footer_left">
          <a href="https://casademont.online/" class="footer_logo">
            <img src="../assets/img/logo_casademont.svg" alt="Casademont">
          </a>
          <div class="footer_description">
            <p v-html="description">
            </p>
          </div>
        </div>
        <div class="footer_right">
          <div class="footer_copyright">
            2024 All right reserved
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import Fb from '@/components/Icons/Fb.vue'
import Twitter from '@/components/Icons/Twitter.vue'
import Insta from '@/components/Icons/Insta.vue'
import {Text} from "vue";

export default {
  name: 'Footer',
  props: {
    description: String
  },
  components: {
    Fb,
    Twitter,
    Insta
  },
}
</script>
