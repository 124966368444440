<template>
  <Header tagline="Настоящие испанские деликатесы"/>
  <Intro title="Винный гид" subtitle="Светлана Добрынина"
         description="Лучший сомелье России* раскрывает секрет идеальных сочетаний вин и деликатесов Casademont! С чем лучше всего сочетать настоящие испанские деликатесы Casademont, чтобы раскрыть всю полноту вкуса и получить максимальное удовольствие?<i>* Лучший сомелье России по версии Where to Eat 2021, вице-призер международного эногастрономического кубка Хереса (Испания) и обладатель 2 Московских Кубков сомелье.</i>"/>
  <Menu :menu-elements="menuItems"/>
  <Footer description="© 2023 ООО «ПИТ-ПРОДУКТ». Россия, Ленинградская обл.,
   Кировский м.р-н, Синявинское г.п.,<br> Подъезд к Синявинским
    высотам от автодороги Кола дор., Зд 2, к. 1"/>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Intro from '@/components/Home/Intro.vue'
import Menu from '@/components/Home/Menu.vue'

export default {
  name: 'HomeView',
  components: {
    Header,
    Intro,
    Menu,
    Footer
  },
  data() {
    const countries = {
      unitedStates: {
        flag: "flag_usa.png",
        coords: {
          x: 18.4,
          y: 42
        },
        img: "wine_usa.svg"
      },
      russia: {
        flag: "flag_russia.png",
        coords: {
          x: 58.5,
          y: 37
        },
        mobileCoords: {
          x: 60,
          y: 37
        },
        img: "wine_russia.svg"
      },
      france: {
        flag: "flag_france.png",
        coords: {
          x: 45.9,
          y: 36
        },
        img: "wine_france.svg"
      },
      italy: {
        flag: "flag_italy.png",
        coords: {
          x: 49.9,
          y: 38.8
        },
        img: "wine_italy.svg"
      },
      spain: {
        flag: "flag_spain.png",
        coords: {
          x : 46.6,
          y: 40.4
        },
        img: "wine_spain.svg"
      },
      mexico: {
        flag: "flag_russia.png",
        coords: {
          x: 19.5,
          y: 54.2
        },
        img: "wine_russia.svg"
      },
      chili: {
        flag: "flag_chili.png",
        coords: {
          x: 27,
          y: 77.5
        },
        img: "wine_chili.svg"
      },
      austria: {
        flag: "flag_austria.png",
        coords: {
          x: 50.4,
          y: 34.2
        },
        img: "wine_austria.svg"
      },
      newZealand: {
        flag: "flag_new_zealand.png",
        coords: {
          x: 95.9,
          y: 87.6
        },
        img: "wine_new_zealand.svg",
        position: "right"
      },
      portugal: {
        flag: "flag_portugal.png",
        coords: {
          x: 44.1,
          y: 40.9
        },
        img: "wine_portugal.svg"
      },
      southAfrica: {
        flag: "flag_south_africa.png",
        coords: {
          x: 52.8,
          y: 82.9
        },
        img: "wine_south_africa.svg"
      },
      australia: {
        flag: "flag_australia.png",
        coords: {
          x: 83.3,
          y: 78.9
        },
        img: "wine_australia.svg",
        position: "right"
      },
      germany: {
        flag: "flag_germany.png",
        coords: {
          x: 47.9,
          y: 33.8
        },
        img: "wine_germany.svg"
      }
    };
    return {
      menuItems: [
        {
          title: "Fuet Extra",
          subtitle: "Фуэт Экстра",
          img: "menu_4.jpg",
          description: "Длинная тонкая колбаса, покрытая благородной плесенью. Гурманы ценят плесень за тонкий грибной аромат, который она придает мясу. Но у нее есть и другие достоинства: например, защита колбасы от преждевременного высыхания. Счищать с Фуэта белую оболочку не принято, от этого он теряет важный компонент своего богатого вкуса. Кроме классического Фуэта, в ассортименте также есть вкусовые вариации: с сыром с голубой плесенью, с инжиром, с перцем, в обсыпке из перца, а также формат снека – Фуэт мини.\n",
          wines: [
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Франция",
              category: "белое сухое",
              type: "Русанн, вионье, гренаш блан",
              description: "Груша, айва, всё на спелом и переспелом жёлтом фрукте. Банан сушеный, лаванда, тимьян,\n" +
                  "фенхель, душица, очень много пряностей. Полнотелое, с прекрасной средней кислотностью,\n" +
                  "сбалансированное, округлое, великолепное теплое вино для тех, кому хочется поплотнее.\n" +
                  "Плотный фуэт с интенсивным вкусом и плотное вино сплелись воедино и образовали красивый\n" +
                  "союз, дополнив ароматную пикантно-пряную историю фуэта нежной айвой и тимьяном. Здесь\n" +
                  "работает принцип \"похожести\" структур, когда вино должно быть таким же плотным, как и\n" +
                  "продукт для пейринга. И да, насыщенное фруктовое белое вино прекрасно чувствует себя в паре с\n" +
                  "колбасой в благородной плесени.",
              countryObj: countries.france
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Испания",
              type: "Монастрель",
              category: "красное сухое",
              description: "Сочный, глубокий, черноягодный монастрель. Здесь и активный дуб и черная вишня, чернослив,\n" +
                  "табак, лакрица, сладкие специи для глинтвейна. Красивый, маскулинный, но хорошо\n" +
                  "интегрированный танин. Невысокая комфортная кислотность. Отличная гладкая пара, абсолютный\n" +
                  "баланс всех компонентов. Одно из самых лучших сочетаний с довольно избирательными и\n" +
                  "капризными фуэтами. Жирность колбасы гасится свежей кислотностью вина, сладкие ноты\n" +
                  "бочковой выдержки балансируют пикантность. Фрукт в вине становится ярче и насыщеннее в этой\n" +
                  "паре.",
              countryObj: countries.spain
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Италия",
              category: "красное сухое, белое сладкое",
              type: "санджовезе, канайоло, колорино, барбера, мускат, альянико, корвина, корвиноне, рондинелла, кроатина, молинара",
              description: "",
              countryObj: countries.italy
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Россия",
              category: "красное сухое, белое сухое, белое сладкое, розовое сухое\n",
              type: "каберне совиньон, пти мансан, пино нуар, ркацители",
              description: "",
              countryObj: countries.russia
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Австрия",
              category: "красное сухое",
              type: "блауфранкиш",
              description: "Дымный, анималистичный, с черникой, черноплодной рябиной, давленой ежевикой, гвоздикой, перцем и тимьяном. Тело сочное, среднее, аккуратная звенящая кислотность, нежный бархатистый танин и ягодное послевкусие. \n" +
                  "Австрийский блауфранкиш - очень гастрономичный и недооцененный сорт винограда, который даёт широчайшую гамму для экспериментов. В данном случае, дымная и животная ароматика с доминантой черных ягод и перца как нельзя лучше дополнит сыровяленый фуэт. Сочная кислотность сгладит острые углы жирности и выйдет на гладкую пару с нежным, ягодным послевкусием. \n",
              countryObj: countries.austria
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Новая Зеландия",
              category: "Красное сухое",
              type: "Пино Нуар",
              description: "Тонкое тело пино нуара, бодрая свежая кислотность и оттенки подлеска (палой осенней листвы и\n" +
                  "грибницы) — это лучшее сочетание с мини-фуэтом. Кислотность пино нуара подчеркивает\n" +
                  "интенсивный вкус фуэта, который, в свою очередь, раскрывает вино по-новому, сообщая ему еще\n" +
                  "больше фруктовости, тела и осенних грибных ароматов.",
              countryObj: countries.newZealand,
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "ЮАР",
              category: "красное сухое",
              type: "пинотаж",
              description: "",
              countryObj: countries.southAfrica
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Португалия",
              category: "красное полусухое",
              type: "туррига, каштелау, камарате, сира",
              description: "",
              countryObj: countries.portugal
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Австралия",
              category: "красное сухое",
              type: "сира (шираз)",
              description: "",
              countryObj: countries.australia,
            }
          ]
        },
        {
          title: "Longaniza",
          subtitle: "Лонганиза",
          img: "menu_2.jpg",
          description: "Благородная сыровяленая колбаса с высоким содержанием мяса и низкой долей жира отличается особенно насыщенным мясным вкусом и премиальной рецептурой, а также нестандартной «изогнутой» формой и сложным циклом производства – продолжительность созревания колбасы может достигать 2 месяцев.",
          wines: [
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Италия",
              category: "игристое",
              type: "Глера (просекко)",
              description: "Классическое сбалансированное просекко, слегка сладковатый финиш, ароматика свежая,\n" +
                  "уходящая в спелые осенние яблоки, грушевое пюре и белые цветы. Свежий и фруктовый стиль\n" +
                  "просекко с аккуратной мягкой кислотностью. Хорошо оттеняет цветочные тона лонганизы,\n" +
                  "балансирует её плотную структуру и насыщенный вкус.",
              countryObj: countries.italy
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Франция",
              category: "белое сухое",
              type: "мускат",
              description: "Свежее, лёгкое и ароматное вино с доминантой белых персиков, фенхеля, личи, розового перца и\n" +
                  "апельсинового цвета. Лонганиза сообщает вину дополнительное тело, раскрывает фруктовость и\n" +
                  "делает вино слаще. Мускат же, напротив, усиливает флоральную составляющую лонганизы,\n" +
                  "подсвечивая максимально все оттенки вкуса колбасы.",
              countryObj: countries.france
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Чили",
              category: "красное сухое",
              type: "карменер",
              description: "Сладкий, ягодный, бочковой, классический карменер, с активным перцем, сладкими пряностями, с послевкусием кофейного бисквита." +
                  "Несмотря на плотный и экспрессивный характер вина,оно имеет весьма гладкий и хорошо интегрированный танин, что позволяет грамотно и мягко дополнить лонганизу, окутав её в сочные сладкие и ягодные ароматы. В сочетании пара кажется очень \"зимней\",  с бисквитно-пряными тонами в послевкусии.",
              countryObj: countries.chili
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "США",
              category: "красное сухое",
              type: "сира (шираз)",
              description: "Черника, чернослив, черешня, дым, черный перец, гвоздика, мускатный орех, тапенада с черным перцем, розмарин, лаванда и тимьян -  все на невероятно легком и гладком теле. " +
                  "Ещё один случай, когда деликатная лонганиза аккуратно принимает в гастропару более \"мускулистое\" и мощное вино. Многообразие пряностей в вине (в особенности, характерный для сиры чёрный перец!) дополняет тонкий вкус лонганизы, делая его более экспрессивным и насыщенным.",
              countryObj: countries.unitedStates
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Испания",
              category: "Розовое сухое",
              type: "Темпранильо, Альбийо",
              description: "Развенчиваем стереотипы. Первое – о том, что среди розовых вин нет серьёзных образцов, и всё,\n" +
                  "что нам остаётся – тешиться бледными розе из Прованса. И второе: что в мощной Рибере могут\n" +
                  "получаться только глубокие полнотелые красные. Вот шикарный пример сложного, глубокого\n" +
                  "розового вина на базе темпранильо глубокого цвета, где румяный лосось сошёлся с луковой\n" +
                  "шелухой. А в аромате насыщенная дубовая пряность, сушеные ягоды, мускус и пало санто.\n" +
                  "Сочетание лонганизы с полнотелым сложным бочковым розе из Риберы дель Дуэро даёт\n" +
                  "превосходный эффект: вино раскрывается дополнительной фруктовостью, которой не было в\n" +
                  "изначальном аромате (дыня, сушеный манго), а лонганиза становится ещё цветочнее и ярче.\n" +
                  "Аккуратный танин розе хорошо балансирует с нежирной тонкой структурой мяса.",
              countryObj: countries.spain
            }
          ]
        },
        {
          title: "Jamón Serrano",
          subtitle: "Хамон Серрано",
          img: "menu_3.jpg",
          description: "Сыровяленый свиной окорок, жемчужина испанской кухни. Для изготовления хамона используются специальным образом подготовленные отборные окорока. Приготовление хамона занимает не менее 7 месяцев и включает в себя засаливание в морской соли, сушку и созревание в тщательно контролируемых условиях. Результатом забот становится нежнейшее мясо, которое буквально тает во рту. Традиционная технология приготовления хамона – национальное достояние Испании. ",
          wines: [
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Россия",
              category: "белое сухое",
              type: "совиньон блан",
              description: "Тропическое , сочное вино без пиразина (присущих совиньонам \"зелёных\", травянистых тонов), абсолютно вызревшее, с идеальным балансом, сладковатое, с лимонным курдом и сливочной меренгой на финише. \n" +
                  "Принято на контрастах сочетать хамон со свежими фруктами, дыня является одним из самых удачных решений. Так вот, здесь с ролью дыни шикарно справляется сочный спелый совиньон блан. И без того шикарная фруктовость вина раскрывается ещё сильнее, хамон отлично дополняет вино, делая его более тельным и округлым. При этом кислотность вина прекрасно гармонизирует жирность и яркий вкус окорока.",
              countryObj: countries.russia
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Италия",
              category: "Красное сухое",
              type: "нерелло маскалезе",
              description: "Нерелло маскалезе имеет профиль, похожий на санджовезе, но больше уходящий в сторону\n" +
                  "клубники, пряностей и легких пикантных, дымных нюансов. В аромате лесная клубника,\n" +
                  "древесные благовония пало санто, сушеные пионы, тимьян, лаванда и полевое сухотравье. Вкус\n" +
                  "великолепный, сбалансированный, гладкий. Живая сочная кислотность, средний, прекрасно\n" +
                  "интегрированный танин. Пара хамона серрано и нерелло маскалезе носит немного рустичный\n" +
                  "характер, но именно это делает её такой соблазнительной. Хорошо сложенный активный танин и\n" +
                  "бодрая кислотность балансируют текстуру хамона, а богатая гамма пряных и дымных ароматов\n" +
                  "делают сочетание сложным, гладким и очень многослойным.",
              countryObj: countries.italy
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Испания",
              category: "Красное сухое",
              type: "Темпранильо, каберне совиньон, мерло",
              description: "Насыщенная ароматика чёрной сливы, вишни, черники, ирги, сушеного банана и шоколада\n" +
                  "(выдающего темпранильо). Кофе, лакрица, какао бобы, специи, табак, сухой, но гладкий танин,\n" +
                  "идеальный баланс. Активный насыщенный вкус хамона раскрывает первичную ароматику вина,\n" +
                  "полную спелых черных фруктов, гладкая танинная структура и хорошая кислотность коррелируют\n" +
                  "с белковой составляющей окорока. Пряности и ноты выдержки в дубе раскрывают\n" +
                  "дополнительные грани вкуса в этом сочетании, делая его очень экспрессивным.",
              countryObj: countries.spain
            }
          ]
        },
        {
          title: "Salchichón",
          subtitle: "Сальчичон",
          img: "menu_7.jpg",
          description: "Сальчичон Экстра производится из высококачественной свинины с добавлением перца и специй, придающих продукту изысканный вкус и неповторимый аромат. Во время созревания колбаса покрывается благородной белой плесенью. Сальчичон великолепно подходит для приготовления закусок «тапас», особенно удобно это при варианте продукта в нарезке.",
          wines: [
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "ЮАР",
              category: "белое сухое",
              type: "шенен блан",
              description: "Полнотелый, насыщенный шенен с маскулинным южноафриканским характером. Яркий золотистый цвет. Зеленый перец, кофейные зерна,  сушеная дыня и яблоки. Невысокая кислотность. \n" +
                  "Яркий сальчичон с насыщенным мясным и рассольно-сливочным вкусом, дополненным ореховыми и цветочными тонами прекрасно сошёлся в паре с плотным густым южноафриканским шененом блан. Шенен сообщает сальчичону дополнительные перечные и кофейные ноты и немного смягчает фруктовыми тонами спелой дыни. Сальчичон же, напротив, раскрывает вино, добавляя ему больше экспрессии и  сглаживая послевкусие. \n",
              countryObj: countries.southAfrica
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Россия",
              category: "белое (оранж) сухое",
              type: "мускат",
              description: "Моченые персики, травы для маринада и солений, фенхель, апельсиновая цедра, хорошая кислотность, легкое тело. Оранжевое для первого знакомства со стилем! \n" +
                  "Отличный баланс между яркой ароматикой сальчичона и фруктовой, цитрусово-пряной стилистикой оранжа из муската. Нежные, слегка \"маринадные\" истории, и сушеная цедра круто раскрывают необычный многосоставной вкус продукта, поддерживая его естественную гастрономичную кислинку.",
              countryObj: countries.russia
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Испания",
              category: "Красное сухое",
              type: "монастрель, гарнача",
              description: "Сочный, глубокий, черноягодный монастрель. Здесь и активный дуб и черная вишня, чернослив,\n" +
                  "табак, лакрица, сладкие специи для глинтвейна. Красивый, маскулинный, но хорошо\n" +
                  "интегрированный танин. Невысокая комфортная кислотность. Монастрель - один из самых\n" +
                  "гастрономичных сортов, кто показал шикарный результат с большинством продуктов, выступив\n" +
                  "гладко и сглаживая сочетания даже в самых непростых парах. Орехово-цветочный стиль\n" +
                  "сальчичона прекрасно дополнен спелыми черными фруктами и сладковатыми тонами бочковой\n" +
                  "выдержки. Бодрый танин аккуратно сработался с белковой составляющей мяса и вино стало ещё\n" +
                  "более гармоничным в этой паре.",
              countryObj: countries.spain
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Австрия",
              category: "красное сухое",
              type: "цвайгельт",
              description: "Пурпурное, сочное, пряное, среднетелое. Доминанта чёрных ягод (черноплодка, черная вишня, ежевика) немного дымности, пряности. Сочное, легкое, танин средний +, обволакивающий, шелковистый. \n" +
                  " Легкий сочный цвайгельт со свежей и бодрой кислотностью прекрасно дополняет насыщенный характер сыровяленого сальчичона, подчеркнув изначальный яркий вкус продукта. Пряные и дымные ноты вина деликатно ложатся на цветочные нюансы колбасы, создавая гармоничную пару. В свою очередь, сальчичон идеально работает, раскрывая в цвайгельте глубину, сочность и делая кислотность еще деликатнее.",
              countryObj: countries.austria
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Германия",
              category: "Красное сухое",
              type: "пино нуар",
              description: "Сочное, яркое, насыщенное, гладкое. Лесные ягоды, мох, подлесок, дым, мускус. Клюква,\n" +
                  "брусника, сладкие пряности. Тут пара работает по проверенной схеме: пино нуар обладает\n" +
                  "прекрасными ароматами, связанными с грибницей, осенним лесом, сухой листвой и даёт\n" +
                  "деликатную ягодность и гармоничную кислотность, позволяя идеально подчеркивать\n" +
                  "насыщенный вкус сыровяленой колбасы и акцентировать внимание на нюансах вроде тонких\n" +
                  "флоральных ароматов. А этот образец из Германии имеет еще и прекрасные нюансы мускуса и\n" +
                  "дыма, которые окончательно влюбляют нас в это сочетание!",
              countryObj: countries.germany
            },
          ]
        },
        {
          title: "Chorizo",
          subtitle: "Чоризо",
          img: "menu_5.jpg",
          description: "Пикантная сыровяленая колбаса, производимая из высококачественного свиного мяса и специй (соль и перец). Основной пряностью, используемой при приготовлении Чоризо, является сладкая паприка, придающая изысканный и необычный вкус. Великолепно подходит для приготовления закусок «тапас», особенно удобно это при варианте продукта в нарезке.",
          wines: [
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Италия",
              category: "Игристое розовое сухое",
              type: "Рабозо",
              description: "«Жемчужное» вино с аккуратным нежным муссом из довольно экспрессивного красного сорта\n" +
                  "рабозо. Малина, барбарис, инжирный персик, леденцовость и мягкий сладковатый финиш. В\n" +
                  "сочетании с чоризо очень важно сбалансировать его выразительную пряность и пикантность,\n" +
                  "подчеркнуть насыщенный вкус и избежать усиления остроты. Нежное игристое розовое фризанте\n" +
                  "(игристое вино с меньшим давлением в бутылке, соответственно с более деликатным перляжем)\n" +
                  "справится с этой задачей на ура. Жемчужное из красного сорта рабозо даёт яркую ягодно-\n" +
                  "леденцовую историю, которая играючи дополняет пикантную колбасу. В послевкусии остаются\n" +
                  "тона перца и паприки, но всё это работает на дополняющей пару выразительной фруктовой\n" +
                  "свежести.",
              countryObj : countries.italy
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Франция",
              category: "белое (оранж) сухое",
              type: "Рислинг",
              description: "Насыщенный цвет и пряная ароматика полевых трав, сушеного яблока, ананаса и дыни, хорошая кислотность и насыщенное тело. Классическая богатая история пряного «эльзасца». Финиш слегка сладковатый, пряный, медовый. \n" +
                  "Рислинги - классическая история, когда речь идёт о пряных и пикантных блюдах. Чоризо - отличный тому пример. Пышный аромат вина с доминантой сладких фруктов и медовыми нюансами на сладковатом финише филигранно работают с остротой перца и паприки, а тело вина достаточно плотное, чтобы справиться и дополнить активный яркий вкус чоризо.",
              countryObj : countries.france
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Испания",
              category: "Красное сухое",
              type: "темпранильо",
              description: "Сухой танин, классический плотный профиль темпранильо, тосты с ванилью, мякоть кокоса,\n" +
                  "черные ягоды, густое тело. Гастрономическая пара точно удалась, когда красное вино в паре с\n" +
                  "едой становится сладковатым, гладким и насыщенно фруктовым. Здесь полнотелая рибера со\n" +
                  "слегка сухим танином становится шёлковой, сладкой и абсолютно сбалансированной, пикантность\n" +
                  "от чоризо на финише даёт небольшую, очень комфортную остроту, не перебивающую вино.",
              countryObj : countries.spain
            },
          ]
        },
        {
          title: "Jamón Cocido",
          subtitle: "Хамон Косидо",
          img: "menu_6.jpg",
          description: "Традиционная испанская ветчина по аутентичной технологии, включающей в себя массированный посол с ограниченным использованием специй, специальную выдержку и варку по особой системе. Результатом становится упругая, но нежная ветчина с монолитной структурой и неповторимым натуральным вкусом.",
          wines: [
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Италия",
              category: "белое сухое игристое",
              type: "глера (просекко)",
              description: "Сочный густой аромат спелой груши, жёлтого яблока и полевого разнотравья. Легкий тон лимонных леденцов и фенхеля. Освежающая кислотность и отличный баланс. Сухой финиш. \n" +
                  "Фруктовая экспрессия игристого вина идеально сочетается с нежной упругой текстурой хамона косидо, кислотность и аккуратная игристость усиливают ощущение свежести в этой паре. Оттенки разнотравья придают вкусу больше глубины и сложности.",
              countryObj : countries.italy
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Чили",
              category: "белое сухое",
              type: "совиньон блан",
              description: "Лайм, лимон, помело, дыня, айва, манго с кожурой, бузина. Спелый, сбалансированный совиньон блан без лишней \"зелени\" в аромате и вкусе. \n" +
                  "Cпелое и объёмное во вкусе вино отлично работает с нежным варёным окороком, сообщая ему больше нежности и раскрываясь в паре с ним яркими ароматами спелых тропических фруктов. Кислотность вина прекрасно гармонирует с хамоном косидо.",
              countryObj : countries.chili
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Испания",
              category: "Белое сухое",
              type: "Вердехо",
              description: "Сочное, легкое, летнее вино с доминантой цитрусовых ароматов (мандарин, лайм, цитрон),\n" +
                  "зеленых персиков и белых цветов. Идеально подходит для любителей легкого и ароматного вина\n" +
                  "со свежей кислотностью.\n" +
                  "Комфортная кислотность, свежий аромат. Сочный и нежный варёный окорок прекрасно\n" +
                  "подчёркивает насыщенную фруктовую экспрессию вина, а вино, в свою очередь, деликатно\n" +
                  "дополняет сливочный вкус мяса нотами белых цветов и свежих цитрусов.",
              countryObj : countries.spain
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Португалия",
              category: "красное сухое",
              type: "альварельяо, педраль, каиньо",
              description: "",
              countryObj : countries.portugal
            },
            {
              title: "Corvezzo, Asolo Prosecco Superiore",
              country: "Новая Зеландия",
              category: "красное сухое",
              type: "пино нуар",
              description: "",
              countryObj : countries.newZealand
            }
          ]
        }
      ],
    };
  },
}
</script>
