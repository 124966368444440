<template>
  <section id="menu">
    <div class="menu_tab_block">
      <div class="container">
        <div class="menu_inner line_mark">
          <div class="menu_navigation_block" :class="{ active: activeMenu  }">
            <h4 class="menu_navigation_btn" @click="toggleMenu">
              Деликатесы
              <Arrow/>
            </h4>
            <div class="menu_navigation swiper-container" ref="swiperContainer">
              <div class="swiper-wrapper">
                <div v-for="(item, index) in menuElements" :key="index" class="swiper-slide">
                  <a href="#" :class="{ active: index === activeIndex }" @click="setActiveMenu($event, index)">
                    {{ item.subtitle }}
                  </a>
                </div>
              </div>
            </div>
            <div class="swiper_navigation">
              <div class="swiper-button-prev swiper-btn">
                <Arrow/>
              </div>
              <div class="swiper-button-next swiper-btn">
                <Arrow/>
              </div>
            </div>
          </div>
          <div class="menu_tabs">
            <div v-for="(item, index) in menuElements" :key="index" :class="{ active: index === activeIndex }"
                 class="menu_tab"
                 :id="'menu_tab_' + index">
              <div class="menu_tab_inner">
                <div class="menu_item_img">
                  <img :src="getImagePath(item.img)" :alt="item.title">
                </div>
                <div class="menu_item_content">
                  <h3 class="menu_item_title">
                    {{ item.title }}
                  </h3>
                  <h6 class="menu_item_subtitle">
                    {{ item.subtitle }}
                  </h6>
                  <div class="menu_item_description">
                    <p>
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wine_map">
      <div class="container" v-if="isMobile">
        <div class="menu_navigation_block">
          <div class="menu_navigation swiper-container" ref="swiperContainerWines">
            <div class="swiper-wrapper">
              <div v-for="(item, index) in filteredWines" :key="index" class="swiper-slide">
              <span :class="{ active: index === activeWineIndex }" v-if="item.description"
                    @click="setActiveWine(index)">
                {{ item.country }}
              </span>
              </div>
            </div>
          </div>
          <div class="swiper_navigation">
            <div class="swiper-button-prev swiper-btn">
              <Arrow/>
            </div>
            <div class="swiper-button-next swiper-btn">
              <Arrow/>
            </div>
          </div>
        </div>
      </div>
      <div class="wine_map_img">
        <img :src="getImagePath('map.svg')" alt="map">
        <div v-for="(item, index) in filteredWines" :key="index"
             :style="setCoords(item)" :class="{ active: index === activeWineIndex }"
             @click="setActiveWine(index)" class="wine_map_area">
          <div class="wine_card" ref="container" v-if="!isMobile" :class="item.countryObj.position">
            <button class="wine_card_close_btn">
              <Times/>
            </button>
            <div class="wine_card_header">
              <div class="wine_card_img">
                <img :src="getImagePath(item.countryObj.img)" :alt="item.title">
              </div>
              <div class="wine_card_properties">
                <ul class="wine_properties">
                  <li>
                    <span class="label">Страна:</span>
                    <span class="value"> {{ item.country }}</span>
                  </li>
                  <li>
                    <span class="label">Сорт:</span>
                    <span class="value"> {{ item.type }}</span>
                  </li>
                  <li>
                    <span class="label">Категория:</span>
                    <span class="value"> {{ item.category }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="wine_card_description">
              <p>{{ item.description }}</p>
              <a href="https://vinoterra.ru/" target="_blank" class="btn">Подробнее</a>
            </div>
          </div>
        </div>
      </div>
      <div class="wine_card" ref="container"
           v-if="isMobile && filteredWines[activeWineIndex]"
           :class="filteredWines[activeWineIndex].countryObj.position">
        <button class="wine_card_close_btn">
          <Times/>
        </button>
        <div class="wine_card_header">
          <div class="wine_card_img">
            <img :src="getImagePath(filteredWines[activeWineIndex].countryObj.img)"
                 :alt="filteredWines[activeWineIndex].title">
          </div>
          <div class="wine_card_properties">
            <ul class="wine_properties">
              <li>
                <span class="label">Страна:</span>
                <span class="value"> {{ filteredWines[activeWineIndex].country }}</span>
              </li>
              <li>
                <span class="label">Сорт:</span>
                <span class="value"> {{ filteredWines[activeWineIndex].type }}</span>
              </li>
              <li>
                <span class="label">Категория:</span>
                <span class="value"> {{ filteredWines[activeWineIndex].category }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="wine_card_description">
          <p>{{ filteredWines[activeWineIndex].description }}</p>
          <a href="https://vinoterra.ru/" target="_blank" class="btn">Подробнее</a>
        </div>
      </div>
    </div>
  </section>
  <div v-click-outside="handleClickOutside"></div>
</template>


<script>
import Arrow from '@/components/Icons/Arrow.vue'
import Times from '@/components/Icons/Times.vue'
import vClickOutside from 'click-outside-vue3'
import Swiper from 'swiper';
import {Navigation} from 'swiper/modules';

Swiper.use([Navigation]);
export default {
  name: 'Menu',
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    menuElements: {
      type: Array,
      required: true,
    },
  },
  components: {
    Arrow,
    Times
  },
  watch: {
    isMobile(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.initializeSwiper();
        });
      }
    }
  },
  methods: {
    getImagePath(filename) {
      return require(`@/assets/img/${filename}`);
    },
    setActiveMenu(event, index) {
      event.preventDefault();
      this.activeIndex = index;
      this.activeWineIndex = null;
    },
    setActiveWine(index) {
      this.activeWineIndex = index;
    },
    setCoords(data) {
      return {
        top: this.isMobile && data.countryObj.mobileCoords ? `${data.countryObj.mobileCoords.y}%` : `${data.countryObj.coords.y}%`,
        left: this.isMobile && data.countryObj.mobileCoords ?`${data.countryObj.mobileCoords.x}%` : `${data.countryObj.coords.x}%`,
        backgroundImage: `url(${this.getImagePath(data.countryObj.flag)})`
      }
    },
    handleClickOutside(event) {
      if(event.target.classList.contains('wine_card_close_btn') || event.target.closest('.wine_card_close_btn')){
        this.activeWineIndex = null;
      }
      if (!event.target.classList.contains('wine_map_area') && !event.target.closest('.wine_map_area')
          && !event.target.classList.contains('wine_card') && !event.target.closest('.wine_card')
          && !event.target.classList.contains('menu_navigation_block') && !event.target.closest('.menu_navigation_block'))
        this.activeWineIndex = null;
    },
    toggleMenu() {
      this.activeMenu = !this.activeMenu;
    },
    initializeSwiper() {
      const swiper = this.$refs.swiperContainer;
      const swiperWines = this.$refs.swiperContainerWines;
      if (window.innerWidth < 768) {
        if (swiper)
          this.swiper = this.initSwiper(swiper);
        if (swiperWines)
          this.swiperWines = this.initSwiper(swiperWines);
      }
    },
    handleWindowResize() {
      if (this.swiper) {
        this.swiper.destroy();
      }
      this.initializeSwiper();
    },
    checkIsMobile() {
      this.isMobile = window.innerWidth < 768;
    },
    initSwiper(instance) {
      return new Swiper(instance, {
        modules: [Navigation],
        slidesPerView: "auto",
        spaceBetween: 20,
        observer: true,
        loop: true,
        observeParents: true,
        navigation: {
          nextEl: instance.closest('.menu_navigation_block').querySelector('.swiper-button-next'),
          prevEl: instance.closest('.menu_navigation_block').querySelector('.swiper-button-prev'),
        }
      });
    }
  },
  mounted() {
    this.initializeSwiper();
    this.checkIsMobile();
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('resize', this.checkIsMobile);
  },
  beforeDestroy() {

    if (this.swiper) {
      this.swiper.destroy();
    }
  },
  computed: {
    filteredWines() {
      return this.menuElements[this.activeIndex].wines.filter(item => item.description);
    }
  },
  data() {
    return {
      activeIndex: 0,
      activeWineIndex: null,
      activeMenu: true,
      swiper: null,
      swiperWines: null,
      isMobile: true
    };
  },
}
</script>
