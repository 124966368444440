<template>
  <header id="header">
    <div class="container">
      <div class="header_inner">
        <div class="header_left">
          <a href="https://casademont.online/" class="header_logo">
            <img src="../assets/img/logo.svg" alt="Logo">
          </a>
          <div class="header_tagline">
            {{ tagline }}
          </div>
        </div>
        <div class="header_right">
          <ul class="header_menu">
            <li>
              <a href="https://casademont.online/" target="_blank">
                Casademont
              </a>
            </li>
            <li>
              <a href="https://vinoterra.ru/" target="_blank">
                VINOTERRA
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: 'Header',
  props: {
    tagline: String
  }
}
</script>
