<template>
  <section id="intro">
    <div class="container">
      <div class="intro_inner">
        <ul class="intro_navigation">
          <li>
            <a href="https://casademont.online/" target="_blank">
              <img src="../../assets/img/logo_casademont.svg" alt="Casademont">
            </a>
          </li>
          <li>
            <span class="separator"></span>
          </li>
          <li>
            <a href="https://vinoterra.ru/" target="_blank">
              <img src="../../assets/img/logo_vinoterra.svg" alt="Vinoterra">
            </a>
          </li>
        </ul>
        <h1 class="intro_title">
          <span>{{ title }}</span>
          <img src="../../assets/img/logo.svg" alt="Casademont">
        </h1>
        <div class="intro_step_navigation line_mark">
          <div class="intro_step_right">
            <h4 class="intro_subtitle">
              {{ subtitle }}
            </h4>
            <div class="intro_description">
              <p v-html="description">
              </p>
            </div>
          </div>
        </div>
        <div class="intro_img">
          <img src="../../assets/img/intro_bg.png" alt="Светлана Добрынина">
        </div>
        <div class="mobile">
          <button class="btn sm" @click="scrollToMenu">
            Перейти к гиду
          </button>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: 'Intro',
  props: {
    title: String,
    subtitle: String,
    description: String
  },
  methods: {
    scrollToMenu(){
      const element = document.getElementById("menu");
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
</script>
